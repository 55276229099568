<template>
  <div class="testimonial-component bg-extra-light">
        <v-container>
            <v-row justify="center">
                <v-col cols="12" sm="10" md="9" lg="7">
                    <div class="text-center">
                        <v-chip small color="error" text-color="white">
                                #{{chip}}
                            </v-chip>
                        <h2 class="section-title font-weight-medium">
                            {{title}}
                        </h2>
                        <p>
                            {{text}}
                        </p>
                    </div>
                </v-col>
            </v-row>
            <v-row class="mt-13" justify="center">
                <v-col 
                    v-for="(item, i) in list"
                    :key="i"
                    cols="12" md="6" lg="4">
                    <v-card class="testimonial-card card-shadow">
                        <v-card-text>
                            <p class="font-weight-light mb-15">
                                {{item.text}}
                            </p>
                            <div class="bottom-bar d-flex align-center">
                                <img :src="item.img" alt="Testimonial"
                                    class="testimonial-img rounded-circle" />
                                <div class="ml-10">
                                    <h6 class="text-uppercase font-weight-medium font-14">
                                        {{item.name}}
                                    </h6>
                                    <div class="d-flex align-center font-13">
                                        <a 
                                        v-for="(star, i) in item.stars"
                                        :key="i"
                                        class="error--text">
                                            <i class="mdi mdi-star"></i></a>
                                    </div>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: "Testimonial",
    props: {
        title: String,
        text: String,
        chip: String,
        list: Array
    }

}
</script>

<style>

</style>
<template>
  <v-container>
    <v-row
        class="d-flex justify-center text-center mt-8">
      <v-card
          class="mb-8 mt-8"
          v-if="this.$store.state.wishlist.length === 0">
        <v-card-title
          style="letter-spacing: 3px; line-height: 1.3;"
        >
          В избранном ничего нет. Чтобы добавить товар в избранное, нажмите на иконку
          <v-btn
              class="mx-2"
              fab
              dark
              x-small
              :color="'rgba(72,162,253,0.65)'"
          >
            <v-icon dark>
              {{'mdi-heart-broken'}}
            </v-icon>
          </v-btn>
          на карточке товара
        </v-card-title>
      </v-card>
    </v-row>
    <v-row
        v-if="this.$store.state.wishlist.length > 0"
        class="text-center"
        justify="start"
    >
      <v-col
          xl="2"
          lg="3"
          md="4"
          v-for="product in listProducts"
          :key="product._id"
      >
        <Card
            class="mx-auto"
            :item="product"
            :img-height="'170'"
            :max-width="'260'"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Card from "./Card";

export default {
  name: "WishList",

  components: {
    Card
  },

  props: {
    listProducts: Array,
  },
}
</script>

<style scoped>

</style>

<template>
  <v-app
    v-resize="onResize">
    <v-navigation-drawer v-model="drawer" class="grad" app fixed temporary width="300">
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item>
            <router-link class="white--text subtitle-1 main-link" exact to="/">
              <v-icon class="white--text mr-4"> mdi-home </v-icon>
              Главная
            </router-link>
          </v-list-item>

          <v-list-item>
            <router-link
              class="white--text subtitle-1 main-link"
              exact
              to="/installation"
            >
              <v-icon class="white--text mr-4"> mdi-wrench </v-icon>
              Монтаж украшений
            </router-link>
          </v-list-item>

          <v-list-item>
            <router-link
              class="white--text subtitle-1 main-link"
              exact
              to="/manufacture"
            >
              <v-icon class="white--text mr-4" style="transform: rotateY(180deg)"> mdi-pencil </v-icon>
              Изготовление на заказ
            </router-link>
          </v-list-item>

          <v-list-item>
            <router-link
              class="white--text subtitle-1 main-link"
              exact
              to="/search"
            >
              <v-icon class="white--text mr-4"> mdi-shopping</v-icon>
              Магазин
            </router-link>
          </v-list-item>

          <v-list-item>
            <router-link
              class="white--text caption main-link ml-7"
              exact
              to="/search?categories=Гирлянды для дома"
            >
              Гирлянды для дома
            </router-link>
          </v-list-item>

          <v-list-item>
            <router-link
              class="white--text caption main-link ml-7"
              exact
              to="/search?categories=Гирлянды для улицы"
            >
              Гирлянды для улицы
            </router-link>
          </v-list-item>

          <v-list-item>
            <router-link
              class="white--text caption main-link ml-7"
              exact
              to="/search?categories=Мастерская"
            >
              Мастерская
            </router-link>
          </v-list-item>

          <v-list-item>
            <router-link
              class="white--text caption main-link ml-7"
              exact
              to="/search?categories=Дюралайт"
            >
              Дюралайт
            </router-link>
          </v-list-item>

          <v-list-item>
            <router-link
              class="white--text caption main-link ml-7"
              exact
              to="/search?categories=Гибкий Неон"
            >
              Гибкий Неон
            </router-link>
          </v-list-item>

          <v-list-item>
            <router-link
              class="white--text caption main-link ml-7"
              exact
              to="/search?categories=Лента LED"
            >
              Лента LED
            </router-link>
          </v-list-item>

          <v-list-item>
            <router-link
              class="white--text caption main-link ml-7"
              exact
              to="/search?categories=Фигуры"
            >
              Фигуры
            </router-link>
          </v-list-item>

          <v-list-item>
            <router-link
              class="white--text caption main-link ml-7"
              exact
              to="/search?categories=Ёлки"
            >
              Ёлки
            </router-link>
          </v-list-item>

          <v-list-item>
            <router-link
              class="white--text caption main-link ml-7"
              exact
              to="/search?categories=Деревья LED"
            >
              Деревья LED
            </router-link>
          </v-list-item>

          <v-list-item>
            <router-link
              class="white--text caption main-link ml-7"
              exact
              to="/search?categories=Новогодний декор"
            >
              Новогодний декор
            </router-link>
          </v-list-item>

          <v-list-item>
            <a class="white--text subtitle-1 main-link" href="tel:8888888">
              <v-icon class="white--text mr-4"> mdi-phone</v-icon>
              +7-900-000-00-00</a>
          </v-list-item>
          <v-list-item>
            <a class="white--text subtitle-1 main-link" href="mailto:fff@fff.ru">
              <v-icon class="white--text mr-4"> mdi-email</v-icon>
              mail@mail.ru</a>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app class="bgr" color="primary" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>LED Ded</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-dialog v-model="search" max-width="400px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="$route.name !== 'Search'"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon large>mdi-magnify</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">Поиск</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="searchQuery"
                    label="Что искать?"
                    autofocus
                    required
                    @keyup.enter="
                      (search = false),
                        $router.push({
                          path: '/search',
                          query: { query: searchQuery },
                        })
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="
                (search = false),
                  $router.push({
                    path: '/search',
                    query: { query: searchQuery },
                  })
              "
            >
              Найти
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app-bar>

    <v-main>
      <router-view></router-view>

      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              style="position: fixed; z-index: 2; width: 100%"
              dark
              color="primary"
            >
              <v-btn icon dark @click="(dialog = false), (current_dialog = '')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ current_dialog }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                @click="$store.commit('destroyWishList')"
                color="error"
                v-if="
                  $store.state.wishlist.length > 0 &&
                  current_dialog === 'Избранное'
                "
              >
                очистить
              </v-btn>
              <v-btn
                @click="$store.commit('destroyViewed')"
                color="error"
                v-if="
                  $store.state.viewed.length > 0 &&
                  current_dialog === 'Просмотренное'
                "
              >
                очистить
              </v-btn>
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>

            <component :is="currentComponent" :listProducts="dataForDisplay">
            </component>
          </v-card>
        </v-dialog>
      </v-row>
    </v-main>

    <v-bottom-navigation color="white" fixed app grow class="bgr">
      <v-btn @click.stop="(dialog = true), (current_dialog = 'Просмотренное')">
        <span>Просмотренное</span>

        <v-badge
          color="green"
          :content="$store.state.viewed.length"
          :value="$store.state.viewed.length"
          overlap
        >
          <v-icon>mdi-history</v-icon>
        </v-badge>
      </v-btn>

      <v-btn @click.stop="(dialog = true), (current_dialog = 'Избранное')">
        <span>Избранное</span>

        <v-badge
          color="green"
          :content="$store.state.wishlist.length"
          :value="$store.state.wishlist.length"
          overlap
        >
          <v-icon>mdi-heart</v-icon>
        </v-badge>
      </v-btn>

      <v-btn @click.stop="(dialog = true), (current_dialog = 'Корзина')">
        <span>Корзина</span>

        <v-badge
          color="green"
          :content="$store.state.cart.length"
          :value="$store.state.cart.length"
          overlap
        >
          <v-icon>mdi-cart</v-icon>
        </v-badge>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import Cart from "./components/Cart";
import WishList from "./components/WishList";
import Viewed from "./components/Viewed";
import axios from "axios";

export default {
  name: "App",

  components: {
    Cart,
    WishList,
    Viewed,
  },

  data: () => ({
    windowSize: {},
    searchQuery: "",
    search: false,
    drawer: false,
    dialog: false,
    current_dialog: "",
    dataForDisplay: [],
    currentComponent: null,
  }),
  methods: {
    onResize () {
        this.$store.commit('resize', { x: window.innerWidth, y: window.innerHeight });
      },
  },

  computed: {
    cart: function () {
      return this.$store.state.cart;
    },

    wishlist: function () {
      return this.$store.state.wishlist;
    },

    viewed: function () {
      return this.$store.state.viewed;
    },
  },

  watch: {
    cart: function () {
      localStorage.setItem("ld__cart", JSON.stringify(this.$store.state.cart));
    },
    wishlist: function () {
      localStorage.setItem(
        "ld__wishlist",
        JSON.stringify(this.$store.state.wishlist)
      );
    },

    viewed: function () {
      localStorage.setItem(
        "ld__viewed",
        JSON.stringify(this.$store.state.viewed)
      );
    },

    current_dialog: function () {
      if (this.current_dialog === "Просмотренное") {
        this.dataForDisplay = this.$store.state.viewed;
        this.currentComponent = Viewed;
      } else if (this.current_dialog === "Избранное") {
        this.dataForDisplay = this.$store.state.wishlist;
        this.currentComponent = WishList;
      } else if (this.current_dialog === "Корзина") {
        this.dataForDisplay = this.$store.state.cart;
        this.currentComponent = Cart;
      }
    },
  },

  async mounted() {
    this.onResize()
    if (
      localStorage.getItem("ld__allItems") &&
      JSON.parse(localStorage.getItem("ld__allItems")).length > 0
    ) {
      this.$store.commit(
        "writeAllProduct",
        JSON.parse(localStorage.getItem("ld__allItems"))
      );
    } else {
      try {
        const url = `https://ledded.ru/cockpit-master/api/collections/get/items?&token=8553d888d64e6b0ead3bc23a4a028b`;
        const response = await axios.get(url);
        const results = response.data.entries;
        results.forEach((item) => this.$set(item, "countProduct", 1));
        this.$store.commit("writeAllProduct", results);
        let items = JSON.stringify(results);
        localStorage.setItem("ld__allItems", items);
      } catch (err) {
        if (err.response) {
          console.log("Server Error:", err);
        } else if (err.request) {
          console.log("Network Error:", err);
        } else {
          console.log("Client Error:", err);
        }
      }
    }

    if (localStorage.getItem("ld__cart")) {
      let cartLocal = JSON.parse(localStorage.getItem("ld__cart"));
      this.$store.commit("writeCart", cartLocal);
    }

    if (localStorage.getItem("ld__viewed")) {
      let viewed = JSON.parse(localStorage.getItem("ld__viewed"));
      this.$store.commit("writeViewed", viewed);
    }

    if (localStorage.getItem("ld__wishlist")) {
      let wishlist = JSON.parse(localStorage.getItem("ld__wishlist"));
      this.$store.commit("writeWishlist", wishlist);
    }
  },
};
</script>

<style>
.bgr {
  background: rgb(63, 94, 251);
  background: radial-gradient(
    circle,
    rgba(63, 94, 251, 1) 0%,
    rgba(252, 70, 107, 1) 100%
  );
}

.led {
  /* background-image: url(https://images2.alphacoders.com/103/thumb-1920-1039991.jpg); */
  background-size: contain;
  background-repeat: repeat;
}

.grad {
  background: rgb(0, 0, 0);
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 1) 0%,
    rgba(8, 31, 56, 1) 100%
  );
}

.main-link {
  text-transform: uppercase;
  text-decoration: none;
}

.main-link:hover {
  transform: scale(1.1);
  opacity: 0.5;
}

.activeLink {
  border-bottom: 1px solid #fff;
}

.activeLink:hover {
  transform: scale(1);
  opacity: 1;
  cursor: auto;
}
</style>

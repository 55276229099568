<template>
  <v-container>
    <v-simple-table
      dense
  >
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-left">
          Номер
        </th>
        <th class="text-left">
          Фото
        </th>
        <th class="text-left">
          Название
        </th>
        <th class="text-left">
          Цена
        </th>
        <th class="text-left">
          Количество
        </th>
        <th class="text-left">
          Стоимость
        </th>
        <th class="text-left">
          Удалить
        </th>
      </tr>
      </thead>
      <tbody class="body-2">
      <tr
          v-for="(item, index) in $store.state.cart"
          :key="item._id"
      >
        <td>{{ index + 1}}</td>
        <td> <img width="50" :src="item.photos[0]"/> </td>
        <td>{{ item.title }}</td>
        <td>{{ item.price }}</td>
        <td>

          <InputCount :id="item._id"/>
          
        </td>
        <td>{{item.countProduct * item.price.split(' ').join('')}}</td>
        <td><v-btn
            @click="removeFromCart(item._id)"
            icon
            color="red"
        >
          <v-icon dark>
            mdi-delete-forever
          </v-icon>
        </v-btn></td>
      </tr>
      <tr style="background-color: #ccf8f8">
        <td colspan="6">Итого</td>
        <td>
          {{$store.getters.getTotal}}
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
  </v-container>
</template>

<script>
import InputCount from "./InputCount.vue"
export default {
  name: "Cart",

  components: {
    InputCount
  },

  data () {
    return {
      cart: this.$store.state.cart,
    }
  },

  updated() {
    localStorage.setItem('ld__cart', JSON.stringify(this.$store.state.cart))
  },

  methods: {
    removeFromCart (item) {
      this.$store.commit('removeFromCart', item);
    }
  },

  watch: {
    cart: function () {
      localStorage.setItem('ld__cart', JSON.stringify(this.$store.state.cart))
    }
  },

  mounted() {
    document.querySelector('table').style.marginTop = '64px'
  }
}
</script>

<style scoped>
  table {
    margin-top: 64px;
  }
</style>

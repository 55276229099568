<template>
  <div>
  <Who
    :title="sections.about.title"
    :text="sections.about.text"
    :chip="sections.about.chip"
    :img="sections.about.img"/>
  <Navigation
    :title="sections.nav.title"
    :chip="sections.nav.chip"
    :text="sections.nav.text"
    :list="sections.nav.list"/>
    <TitleWithText 
      :title="sections.features.title" 
      :text="sections.features.text"
      :chip="sections.features.chip"/>

  <FeaturesSection :list="sections.features.list"/>
  <FormBanner
    :title="sections.form.title"
    :text="sections.form.text"
    :img="sections.form.img"/>
  <Testimonial
    :title="sections.reviews.title"
    :text="sections.reviews.text"
    :chip="sections.reviews.chip"
    :list="sections.reviews.list"/>
  <CallToAction/>
  </div>
</template>

<script>
  import Who from '../components/home/Who.vue'
  import TitleWithText from "../components/pageComponents/TtitleWithText.vue";
  import Navigation from '../components/home/Navigation.vue'
  import FeaturesSection from '../components/pageComponents/FeaturesSection.vue'
  import FormBanner from '../components/home/FormBanner.vue'
  import Testimonial from '../components/home/Testimonial.vue'
  import CallToAction from '../components/home/CallToAction.vue'

  export default {
    name: 'Home',
    components: {
      Who,
      TitleWithText,
      Navigation,
      FeaturesSection,
      FormBanner,
      Testimonial,
      CallToAction
    },
    
    data () {
      return {
        sections: {
          about: {
            chip: 'отновогодим всех',
            title: 'Новогодний декор',
            text: 'У нас действительно много новогодних украшений. В нашем каталоге каждый сможет найти товар для себя. А если ваша фантазия не желает умещаться в рамки каталогов, наше производство к вашим услугам - фигуры, консоли, подвески и прочие изделия на основе LED дюралайта и гирлянд. А ещё в нашем арсенале есть штат квалифицированных монтажников',
            img: require('@/assets/logo.png'),
          },
          nav: {
            title: 'Основные категории',
            chip: 'что мы можем',
            text: 'Каждый Новый год весь город разцветает сотнями разноцветных огней. Во многом, именно это дарит всем нам неповторимое праздничное настроение. Станьте соучастником этой новогодней феерии. Приобретайте новогодний декор по лучшим ценам у нас. Сделаем Новый год ярче!',
            list: [
              {
                img: require('@/assets/images/portfolio/sosny.jpg'),
                title: 'Гирлянды для улицы',
                link: '/search?categories=Гирлянды для улицы'
              },
              {
                img: require('@/assets/images/portfolio/indigo.jpg'),
                title: 'Монтаж украшений',
                link: '/installation'
              },
              {
                img: require('@/assets/images/portfolio/forHome.jpg'),
                title: 'Гирлянды для дома',
                link: '/search?categories=Гирлянды для дома'
              },
              {
                img: require('@/assets/images/portfolio/ball.jpg'),
                title: 'Изготовление новогодних фигур/консолей',
                link: '/manufacture'
              },
            ]
          },
          features: {
              chip: 'почему мы',
              title: 'Преимущества',
              text: 'Предположим, что мы выяснили - Вам аболютно точно нужны новогодние украшения (или их монтаж). Почему же Вам стоит обратиться именно к нам? Ознакомьтесь со списком наших основных преимуществ',
              list: [
                  {
                      id: 1,
                      title: 'Лучший интернет магазин',
                      text: 'На страницах нашего интернет магазина представлен огромный ассортимент новогодних украшений и сопутствующих товаров. При этом поиск, фильтрация, сортировка работают мгновенно. Нет раздражающих пауз и перезагрузок страницы.',
                      icon: 'mdi mdi-shopping-search'
                  },
                  {
                      id: 2,
                      title: 'Квалифицированный монтаж',
                      text: 'Собрали лучших монтажников с многолетним опытом. Как всегда кадры решают всё. Мы осознаём это, и в своей кадровой политике исключительно избирательны. Плодами этой избирательности стала команда первоклассных монтажников. Они и вам понравятся',
                      icon: 'mdi mdi-star'
                  },
                  {
                      id: 3,
                      title: 'Превосходное качество',
                      text: 'Для производства наших изделий используем только проверенные комплектующие, что позволяет работать им долго. Мы уверены в своей продукции, в доказательство своей уверенности - даём гарантию на все изделия',
                      icon: 'mdi mdi-quality-high'
                  }
              ]
          },
          form: {
            title: 'Новый год близко',
            text: 'Максимално быстро обрабатываем все обращения, ведь до Нового года осталось совсем немного времени. Оставьте свой номер телефона и мы свяжемся с Вами через 120 секунд',
            img: require('@/assets/collazh.png'),
          },
          reviews: {
            title: 'Отзывы наших клиентов',
            text: 'Конечная цель всех наших стараний - довольный клиент. И мы досигаем этой цели. Вот что говорят наши клиенты',
            chip: 'отзывы',
            list: [
              {
                text: '“Заказывал контурную подсветку загородного дома. Как полагается в последний момент. Но ребята из LED Ded (от менеджера, принявшего заказ, до монтажников) отработали очень быстро и мы всё успели. Спасибо LED Ded”',
                img: 'https://sun9-71.userapi.com/s/v1/ig2/ytslLDb6n008LJ27UQwuZOzOsqz7rDi14kUc2hDWlbRx35J9lCqy4oAgXAOWUjGJ8W-o5tCpNc7c4X4sfxPexyHb.jpg?size=100x100&quality=96&crop=165,140,823,823&ava=1',
                name: 'Николай',
                stars: 5
              },
              {
                text: '“Добрый день. Короткая история о том, как приобрела гирлянду белт-лайт и осталась довольна: однажды я приобрела гирлянду белт-лайт и осталась довольна ))) А если серьёзно - рекомендую LED Ded, они действительно профессионалы”',
                img: 'https://sun9-55.userapi.com/s/v1/ig2/oaizyES2ktTFu7mVEJVaP4yHkW8FivcARuwXEQhIXhYSpQddA-qWh52-CkQFvnb_si2TDylTCpq3uRZCmpsFSflA.jpg?size=100x100&quality=95&crop=0,166,1070,1070&ava=1',
                name: 'Ольга',
                stars: 5
              },
              {
                text: '“Хотел поставить грандиозного снеговика на участке. Позвонил в LED Ded, обсудили детали по телефона, нарисовали будущее изделие. Через несколько дней привезли на участок, всё установили и подключили. Класс”',
                img: 'https://sun9-85.userapi.com/s/v1/ig2/a9a6CVC4aZDOd7C_4QookYvaKmuqwrVrMYE1R7-Z1OQwh9FFi47oCN5p_HJtN8jr07ZpEHz92OozfRUziUUDTWjQ.jpg?size=100x100&quality=95&crop=0,300,864,864&ava=1',
                name: 'Виктор',
                stars: 4
              },
            ]
          },
        },
      }
    },

  }

</script>

<style>
</style>

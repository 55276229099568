<template>
    <div class="feature1-component">
            <v-container>
                <v-row justify="center" class="mt-13">
                    <v-col
                    v-for="item in list"
                    :key="item.id"
                    sm="6"
                    lg="4"
                    cols="12" 
                    >
                        <v-card hover rounded="lg">
                            <v-card-text class="text-center">
                                <div class="icon-round bg-light-info text-center">
                                    <i :class="item.icon"></i>
                                </div>
                                <h5
                                    class="font-weight-medium" 
                                    :class="{'text-h4': $store.state.windowSize.x > 812, 'text-h5': $store.state.windowSize.x <= 812}"
                                    >{{item.title}}</h5>
                                <p class="mt-10 mb-8">
                                    {{item.text}}
                                </p>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </div>
</template>

<script>
export default {
    name: 'FeaturesSection',
    props: {
        list: Array
    }

}
</script>

<style>

</style>
<template>
    <v-card
        class="my-12 grad white--text custom-border"
        text-color="#fff"
        :max-width="maxWidth"
        height="510"
    >
      <v-img
          :height="imgHeight"
          :src="item.photos[0]"
      ></v-img>

      <v-badge
      overlap
      tile
      content="Просмотрено"
      offset-x="100px"
      offset-y="-10px"
      color="#808080"
      :value="$store.state.viewed.find(p => p._id === item._id) ? true : false"
      >
      <v-card-title
          style="min-height: 130px"
          class="body-1"
      >{{item.title}}</v-card-title>
      </v-badge>

      <v-card-text
      style="min-height: 100px"
      >

        <div class="my-4 text-subtitle-2 white--text">
          <v-divider class="mx-4"></v-divider>
          Код товара: {{item.code}}
        </div>
      </v-card-text>

      <v-divider colors="white" class="mx-4"></v-divider>

      <v-card-actions
          style="border-top: 1px solid white"
          class="d-flex justify-space-between py-4"
      >

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                @click.stop="addToWishlist(item)"
                class="mx-2"
                v-bind="attrs"
                v-on="on"
                fab
                dark
                small
                :color="$store.state.wishlist.findIndex(p => p._id === item._id) === -1 ? 'rgba(72,162,253,0.65)' : '#a90000'"
            >
              <v-icon dark>
                {{$store.state.wishlist.findIndex(p => p._id === item._id) === -1 ? 'mdi-heart-broken' : 'mdi-heart'}}
              </v-icon>
            </v-btn>
          </template>
          <span>{{$store.state.wishlist.findIndex(p => p._id === item._id) === -1 ? 'Добавить в избранное' : 'Убрать из избранного'}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                @click.stop="addToCart(item)"
                class="mx-2"
                v-bind="attrs"
                v-on="on"
                fab
                dark
                small
                :color="$store.state.cart.findIndex(p => p._id === item._id) === -1 ? 'primary' : 'green'"
            >

            <v-badge
              :color="$store.state.cart.findIndex(p => p._id === item._id) === -1 ? 'transparent' : 'primary'"
              :content="$store.state.cart.find(p => p._id === item._id) ? $store.state.cart.find(p => p._id === item._id).countProduct : ''"
              :value="$store.state.cart.find(p => p._id === item._id) ? true : false"
              overlap
            >

            <v-icon dark>
              {{$store.state.cart.findIndex(p => p._id === item._id) === -1 ? 'mdi-cart-plus' : 'mdi-check'}}
            </v-icon>
          </v-badge>
            </v-btn>
          </template>
          <span>{{$store.state.cart.findIndex(p => p._id === item._id) === -1 ? 'Добавить в корзину' : 'ещё +1'}}</span>
        </v-tooltip>


        <h3 class="white--text">{{ item.price }} Руб</h3>
      </v-card-actions>
    </v-card>
</template>

<script>
export default {
  name: "Card",
  props: {
    item: Object,
    maxWidth: String,
    imgHeight: String,
  },
  methods: {
    addToCart(product) {
      this.$store.commit('addToCart', product)
    },

    addToWishlist(product) {
      this.$store.commit('addToWishlist', product)
    },
  }
}
</script>

<style scoped>
.custom-border{
  border-radius: 5px;
}
.custom-border:hover {
  box-shadow: 0px 1px 23px 9px rgba(77, 212, 241, 0.52)!important;
}
</style>

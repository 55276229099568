import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cart: [],
    wishlist: [],
    viewed: [],
    allProducts: [],
    windowSize: {},
  },

  getters: {
    getTotal: state => {return state.cart.reduce((acc, item) => {return acc + item.price.split(' ').join('') * item.countProduct}, 0)}
  },

  mutations: {
    resize (state, sizes) {
      state.windowSize = sizes;
    },
    addToCart (state, product) {
      let indexProduct = state.cart.findIndex(item => product._id === item._id)
      if (indexProduct === -1) {
        product.countProduct = 1
        state.cart.push(product)
      } else {
        state.cart[indexProduct].countProduct ++
        localStorage.setItem('ld__cart', JSON.stringify(state.cart))
      }
    },


    removeFromCart (state, id) {
      let indexProduct = state.cart.findIndex(item => id === item._id)
      state.cart[indexProduct].countProduct = 1
      state.cart.splice(indexProduct, 1)
    },

    addToWishlist (state, product) {
      let indexProduct = state.wishlist.findIndex(item => product._id === item._id)
      if (indexProduct === -1) {
        state.wishlist.push(product)
      } else {
        state.wishlist.splice(indexProduct, 1)
      }
    },

    writeAllProduct (state, products) {
      state.allProducts = products
    },

    writeCart (state, products) {
      state.cart = products
    },

    writeViewed(state, products) {
      state.viewed = products
    },

    writeWishlist(state, products) {
      state.wishlist = products
    },

    destroyWishList (state) {
      state.wishlist = []
    },

    destroyViewed(state) {
      state.viewed = []
    },

    updateCount (state, obj) {
      state.cart.find(item => item._id === obj.id).countProduct = obj.v
    },

    addToViewed (state, product) {
      let indexProduct = state.viewed.findIndex(item => product._id === item._id)
      if (indexProduct === -1) {
        state.viewed.push(product)
      }
    }
  },
  actions: {
  },
  modules: {
  }
})

<template>
    <div>
        <div class="banner1-component">
        <v-container>
            <v-row justify="center">
                <v-col cols="12" md="7" lg="6" class="d-flex align-center">
                    <div class="text-center text-md-left">
                        <v-chip small class="bg-dark" text-color="white">
                            #{{chip}}
                        </v-chip>
                        <h2 class="
                    banner1-title
                    font-weight-bold
                    text-uppercase
                    white--text
                ">
                            {{title}}
                        </h2>
                        <p class="white--text op-8 font-weight-regular">
                            {{text}}
                        </p>
                        <div class="mt-16 pt-2">
                            <v-btn large rounded to="/basic-components" class="btn-custom-md" depressed
                                color="white" elevation="0">
                                Написать нам
                            </v-btn>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="5" lg="5">
                    <v-img :src="require('@/assets/logo.png')" :alt="title" />
                </v-col>
            </v-row>
        </v-container>
    </div>

    </div>
</template>

<script>
export default {
    name: 'Who',
    props: {
        title: String,
        text: String,
        chip: String,
        img: String,
    }
}
</script>

<style lang="scss">
</style>

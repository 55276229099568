<template>
  <div class="c2a1-spacer" style="background-color: #4c5cf1;">
        <v-container>
            <div class="d-block d-md-flex align-center">
                <h2 class="c2a-title white--text font-weight-medium">
                    Остались вопросы? Задайте их по телефону
                </h2>
                <div class="ml-auto mt-5 mt-md-0">
                    <v-btn large to="/" class="btn-custom-md" outlined color="white" elevation="0" block>
                        +7-900 000 0000
                    </v-btn>
                </div>
            </div>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'CallToAction',

}
</script>

<style>

</style>
<template>
  <div class="feature2-component mini-spacer bg-extra-light pb-4">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" md="10" lg="7">
                        <div class="text-center">
                            <v-chip small color="#4c5cf1" text-color="white">
                                #{{chip}}
                            </v-chip>
                            <h2 class="section-title font-weight-medium">
                                {{title}}
                            </h2>
                            <p>
                                {{text}}
                            </p>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                    <v-row justify="center" class="feature2-spacer">
                    <v-col 
                        v-for="(item, i) in list"
                        :key="i"
                        cols="12" lg="5" class="mb-15">
                        <v-card elevation="0">
                            <v-row>
                                <v-col cols="12" md="8" class="py-0">
                                    <v-card-text>
                                        <div class="d-flex align-center">
                                            <v-row>
                                                <v-col cols="12" md="4">
                                                    <img 
                                                    v-show="$store.state.windowSize.x > 960"
                                                    :src="item.img"
                                                        class="rounded img-fluid" alt="feature2"/>
                                                    <!-- <img src="@/assets/images/features/2/img1.jpg"
                                                        class="img-fluid rounded" alt="feature2" /> -->
                                                </v-col>
                                                <v-col cols="12" md="8" class="px-md-0 d-flex align-center">
                                                    <h3 
                                                    :class="{'font-weight-medium font-18': $store.state.windowSize.x > 960, 'text-h4 pa-5': $store.state.windowSize.x <= 960}">
                                                    {{item.title}}
                                                    </h3>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-card-text>
                                </v-col>
                                <v-col cols="12" md="4" class="py-0">
                                    <router-link :to="item.link">
                                    <v-btn 
                                    style="padding-bottom: 10px; padding-top: 10px;"
                                    block 
                                    depressed 
                                    color="#4c5cf1" 
                                    class="linking feature2-action-btn">
                                        <span class="white--text">Подробнее</span> <i class="mdi mdi-arrow-right white--text"></i>
                                    </v-btn>
                                    </router-link>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </div>
</template>

<script>
export default {
    name: 'Navigation',
    props: {
        title: String,
        chip: String,
        text: String,
        list: Array,
        mobile: Boolean
    }

}
</script>

<style>

</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import(/* webpackChunkName: "shop" */ '../views/Shop.vue'),
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
    props: (route) => ({ query: route.query.query })
  },

  {
    path: '/installation',
    name: 'Installation',
    component: () => import(/* webpackChunkName: "search" */ '../views/Installation.vue'),
  },

  {
    path: '/manufacture',
    name: 'Manufacture',
    component: () => import(/* webpackChunkName: "search" */ '../views/Manufacture.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'activeLink',
  routes
})

export default router

<template>
  <div class="bg-extra-light">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" sm="10" md="9" lg="7">
                        <div class="text-center">
                            <v-chip small color="error" text-color="white">
                                #{{chip}}
                            </v-chip>
                            <h2 class="section-title font-weight-bold">{{title}}</h2>
                            <p>{{text}}</p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
</template>

<script>
export default {
    name: 'TitleWithText',

    props: {
        title: String,
        text: String,
        chip: String,
    }

}
</script>

<style>

</style>
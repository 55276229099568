<template>
    <input 
        v-model="count"
        style="border: 1px solid #5079f3; border-radius: 2px; max-width: 50px; text-align: center"/>
</template>

<script>
export default {
    name: "InputCount",

    props: ['id'],

    data () {
        return {
            
        }
    },

    computed: {
        count: {
            get () {
                return this.$store.state.cart.find(i => i._id === this.id).countProduct
            },
            set (value) {
                this.$store.commit('updateCount', {v: value, id: this.id});
                localStorage.setItem('ld__cart', JSON.stringify(this.$store.state.cart))
            }
        }
    }
}
</script>
<template>
    <div class="formbanner1-component bg-extra-light" style="background-color: #000">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" md="7" lg="5" class="d-flex align-center">
                        <div class="text-center text-md-left">
                            <h2 class="formbanner1-title font-weight-bold white--text">
                                {{title}}
                            </h2>
                            <p class="mt-16 pt-4 white--text">
                                {{text}}
                            </p>
                            <div class="mt-16 pt-2">
                                <v-alert
                                    v-show="resStatus"
                                    dense
                                    text
                                    :type="resStatus === 'success' ? 'success' : 'error'"
                                    >
                                    {{resStatus === 'success' ? 'Сообщение успешно отправлено. Мы скоро свяжемся с вами' : 'Не удалось отправить сообщение'}}
                                </v-alert>
                                <form
                                    @submit.prevent="sendForm()">
                                    <div class="stylish-input-group">
                                        <v-text-field 
                                        v-model="input"
                                        required
                                        minlength="5"
                                        maxlength="25"
                                        placeholder="Номер телефона" 
                                        solo></v-text-field>
                                        <v-btn 
                                        tag="button"
                                        type="submit"
                                        :disabled="sending"
                                        rounded 
                                        color="primary" 
                                        elevation="0"
                                        class="text-uppercase font-weight-medium" 
                                        dark>
                                            Жду звонка
                                        </v-btn>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="5" lg="6" class="ml-auto">
                        <v-img class="mx-auto" :src="img"  width="500" alt="banner" />
                    </v-col>
                </v-row>
            </v-container>
        </div>
</template>

<script>
export default {
    name: 'FormBanner',
    props: {
        title: String,
        text: String,
        img: String,
    },

    data () {
        return {
            input: '', 
            sending: false,
            resStatus: ''
        }
    },

    //-1001775160452
   // 5014291925:AAEyoRuxns1NXXhj5c7rOrCTagZxSF39J48

    methods: {
        sendForm () {
            this.sending = true
            console.log(this.input)
                fetch('https://ledded.ru/cockpit-master/api/forms/submit/orders?token=8553d888d64e6b0ead3bc23a4a028b', {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            name: 'kk',
                            phone: 'kkk'
                        })
                    })
                    // .then(res=>res.json())
                    // .then(entry => console.log(entry))
                    .then((res) => {
                        console.log(res)
                        this.input = ''
                        this.sending = false
                        if (res.status == 200) {
                            this.resStatus = 'success'
                            setTimeout(() => {
                                this.resStatus = ''
                            }, 5000)
                        }
                        else {
                            this.resStatus = 'error'
                            setTimeout(() => {
                                this.resStatus = ''
                            }, 5000)
                        }
                    })
        }
    }

}
</script>

<style>

</style>